body * {
    font-family: "Noto Sans JP";
}

:root {
    --nav-bar-pc-width: 60mm;

    --main-bg-color: #305060;
    --main-bg-color2: #80C8FF;
    --main-bg-color-dark: black;
    --main-bg-color-dark2: #005E9E;
    --nav2-menu-color: #408FC7A0;
    --nav2-menu-color-d: #404040A0;

    --nav2-menu-bg: linear-gradient(90deg, var(--nav2-menu-color-d), var(--nav2-menu-color));

    --moz-bg: linear-gradient(90deg, var(--main-bg-color), var(--main-bg-color2));
    --webkit-bg: linear-gradient(90deg, var(--main-bg-color), var(--main-bg-color2));
    --ie-bg: linear-gradient(90deg, var(--main-bg-color), var(--main-bg-color2));

    --moz-bg-dark: linear-gradient(90deg, , var(--main-bg-color-dark), var(--main-bg-color-dark2));
    --webkit-bg-dark: linear-gradient(90deg, , var(--main-bg-color-dark), var(--main-bg-color-dark2));
    --ie-bg-dark: linear-gradient(90deg, var(--main-bg-color-dark), var(--main-bg-color-dark2));

    --main-bg-annotation1: #404040;
    --main-bg-annotation2: #38C060;
    --main-bg-annotation-dark: black;

    --moz-bg-annotation: linear-gradient(90deg, var(--main-bg-annotation1), var(--main-bg-annotation2));
    --webkit-bg-annotation: linear-gradient(90deg, var(--main-bg-annotation1), var(--main-bg-annotation2));
    --ie-bg-annotation: linear-gradient(90deg, var(--main-bg-annotation1), var(--main-bg-annotation2));

    --ie-bg-annotation-dark: linear-gradient(90deg, var(--main-bg-annotation-dark), var(--main-bg-annotation2));

    --topic-color1: #F0F0F0;
    --topic-color2: #F8F8F8;

    --moz-topic:  -moz-linear-gradient(top, var(--topic-color1), var(--topic-color2));
    --webkit-topic: -webkit-linear-gradient(top, var(--topic-color1), var(--topic-color2));
    --ie-topic: linear-gradient(to bottom, var(--topic-color1), var(--topic-color2));

    --topic-color1-dark: #D8D8D8;
    --topic-color2-dark: #E0E0E0;

    --moz-topic-dark:  -moz-linear-gradient(top, var(--topic-color1-dark), var(--topic-color2-dark));
    --webkit-topic-dark: -webkit-linear-gradient(top, var(--topic-color1-dark), var(--topic-color2-dark));
    --ie-topic-dark: linear-gradient(to bottom, var(--topic-color1-dark), var(--topic-color2-dark));

    --topic-color1-dark2: #787878;
    --topic-color2-dark2: #888888;

    --moz-topic-dark2:  -moz-linear-gradient(top, var(--topic-color1-dark2), var(--topic-color2-dark2));
    --webkit-topic-dark2: -webkit-linear-gradient(top, var(--topic-color1-dark2), var(--topic-color2-dark2));
    --ie-topic-dark2: linear-gradient(to bottom, var(--topic-color1-dark2), var(--topic-color2-dark2));

    --topic-hover1: #D0E8FF;
    --topic-hover2: #E0E8FF;

    --moz-topic-hover:  -moz-linear-gradient(top, var(--topic-hover1), var(--topic-hover2));
    --webkit-topic-hover: -webkit-linear-gradient(top, var(--topic-hover1), var(--topic-hover2));
    --ie-topic-hover: linear-gradient(to bottom, var(--topic-hover1), var(--topic-hover2));


    --topic-title-color1: #E0E8FF;
    --topic-title-color2: #E8F0FF;

    --moz-topic-title: -moz-linear-gradient(top, var(--topic-title-color1), var(--topic-title-color2));
    --webkit-toppic-title: -webkit-linear-gradient(top, var(--topic-title-color1), var(--topic-title-color2));
    --ie-toppic-title: linear-gradient(to bottom, var(--topic-title-color1), var(--topic-title-color2));

    --caution1: #FF9898;
    --caution2: #FFAAAA;

    --moz-caution:  -moz-linear-gradient(top, var(--caution1), var(--caution2));
    --webkit-caution: -webkit-linear-gradient(top, var(--caution1), var(--caution2));
    --ie-caution: linear-gradient(to bottom, var(--caution1), var(--caution2));

    --caution1-dark: #FF6868;
    --caution2-dark: #FF7A7A;

    --moz-caution-dark:  -moz-linear-gradient(top, var(--caution1-dark), var(--caution2-dark));
    --webkit-caution-dark: -webkit-linear-gradient(top, var(--caution1-dark), var(--caution2-dark));
    --ie-caution-dark: linear-gradient(to bottom, var(--caution1-dark), var(--caution2-dark));

    --sunday1: #FFD0D0;
    --sunday2: #FFE0E0;

    --moz-sunday:  -moz-linear-gradient(top, var(--sunday1), var(--sunday2));
    --webkit-sunday: -webkit-linear-gradient(top, var(--sunday1), var(--sunday2));
    --ie-sunday: linear-gradient(to bottom, var(--sunday1), var(--sunday2));

    --saturday1: var(--topic-title-color1);
    --saturday2: var(--topic-title-color2);

    --moz-saturday:  -moz-linear-gradient(top, var(--saturday1), var(--saturday2));
    --webkit-saturday: -webkit-linear-gradient(top, var(--saturday1), var(--saturday2));
    --ie-saturday: linear-gradient(to bottom, var(--saturday1), var(--saturday2));

    --category1: var(--saturday1);
    --category2: var(--saturday2);

    --moz-category:  -moz-linear-gradient(top, var(--category1), var(--category2));
    --webkit-category: -webkit-linear-gradient(top, var(--category1), var(--category2));
    --ie-category: linear-gradient(to bottom, var(--category1), var(--category2));

    --tag-caution-color1: var(--sunday1);
    --tag-caution-color2: var(--sunday2);

    --moz-bg-tag-caution:  -moz-linear-gradient(top, var(--tag-caution-color1), var(--tag-caution-color2));
    --webkit-bg-tag-caution: -webkit-linear-gradient(top, var(--tag-caution-color1), var(--tag-caution-color2));
    --ie-bg-tag-caution: linear-gradient(to bottom, var(--tag-caution-color1), var(--tag-caution-color2));
    
    --small-menu-height: 3rem;

    --activity-title-color1: #E8E8E8;
    --activity-title-color2: #F8F8F8;

    --moz-bg-activity-title:  -moz-linear-gradient(top, var(--activity-title-color1), var(--activity-title-color2));
    --webkit-bg-activity-title: -webkit-linear-gradient(top, var(--activity-title-color1), var(--activity-title-color2));
    --ie-bg-activity-title: linear-gradient(to bottom, var(--activity-title-color1), var(--activity-title-color2));
    
    --activity-title-border-color: #C0C0C0;

    --activity-subtitle-border-color: #88A0FF;
}

.absolute {
    position: absolute;
}

.tabed-content {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
}

.relative {
    position: relative;
}

.tag-container {
    position: absolute;
    margin-right: 0.25rem;
    text-align: right;
    font-size: 0.8rem;
    height: 1.6rem;
    bottom: 0;
    left: 0mm;
    right: 0;
}

.date-container {
    padding-left: 0.5rem;
    position: absolute;
    font-size: 0.8rem;
    color: gray;
    font-weight: bold;
    text-align: left;
    left: 0;
}

.grid-col-2 {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.grid-col-1-10-1 {
    width: 100%;
    align-items: center;
    display: grid;
    grid-template-columns: 1.5em 10fr 1fr;
}

.grid-col-4 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-col-4 a {
    text-align: center;
}

.icon-youtube {
    position: absolute;
    top: -0.75rem;
    right: 0.75rem;
    color: red;
    font-size: 1.4em;
}

.youtube{
    width:100%;
    padding-bottom: 56.25%;
    height:0px;
    position: relative;
}

.youtube iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.icon {
    margin-top: 1mm;
    margin-bottom: 1mm;
    height: 25mm;
    width: 25mm;
}

.icon-nav {
    height: 4rem;
    width: 4rem;
}

.scene-box {
    border: 1px solid lightgray;
    margin-bottom: 0.25rem;
}

.scene-title {
    background: var(--moz-bg-activity-title);
    background: var(--webkit-bg-activity-title);
    background: var(--ie-bg-activity-title);
}

.scene-box-active {
    border: 1px solid var(--caution1);
    margin-bottom: 0.25rem;
}

.scene-title-active {
    background: var(--moz-sunday);
    background: var(--webkit-sunday);
    background: var(--ie-sunday);
}


.button-caution {
    border-width: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: inline-block;
    background: var(--moz-caution);
    background: var(--webkit-caution);
    background: var(--ie-caution);
    color: black;
}

.button-caution:hover {
    border-width: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background: var(--moz-caution-dark);
    background: var(--webkit-caution-dark);
    background: var(--ie-caution-dark);
    color: white;
}

.button-normal {
    border-width: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: inline-block;
    background: var(--moz-topic);
    background: var(--webkit-topic);
    background: var(--ie-topic);
    color: black;
}

.button-border-right {
    border-right: 1px solid var(--topic-color2-dark2);
}

.button-normal:hover {
    background: var(--moz-topic-dark2);
    background: var(--webkit-topic-dark2);
    background: var(--ie-topic-dark2);
    color: white;
}


.button-primary {
    border-width: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: inline-block;
    background: var(--moz-bg);
    background: var(--webkit-bg);
    background: var(--ie-bg);
    color: white;
}

.button-primary:hover {
    background: var(--moz-bg-dark);
    background: var(--webkit-bg-dark);
    background: var(--ie-bg-dark);
    color: white;
}

.if-map {
    margin: 0 1rem 0 0;
    min-width: 90%;
    min-height: 15rem;
    max-height: 35%;
}

.nav2-main {
    right: 0;
    width: 3rem;
    font-size: 1.5rem;
    position: fixed;
    color: white;
    z-index: 100000;
    height: 3rem;
}

.nav2-expander {
    font-size: 1rem;
    padding-top: 0.5rem;
    top: 0;
    right: 0;
    position: fixed;
    color: white;
    z-index: 10001;
    width: 3rem;
    height: 3rem;
    text-align: center;
}

.nav2-menu {
    font-size: 1rem;
    z-index: inherit;
    position: fixed;
    top: 3rem;
    background: var(--moz-bg);
    background: var(--webkit-bg);
    background: var(--ie-bg);
}

.nav2-menu-keep-top {
    width: 1rem;
    height: 7.5rem;
    background-color: #00000040 !important;
}

.nav2-menu-keep {
    width: 1rem;
    height: 3.75rem;
    border-top: 1px solid #FFFFFF40;
    background-color: #00000040 !important;
}

.nav2-menu-box {
    right: 0;
    z-index: inherit;
    position: fixed;
    top: 3rem;
    background-color: var(--nav2-menu-color);
    transition: all 300ms 0s ease;
}

.nav2-menu-box-dispose {
    right: -12rem;
}

.nav2-button-top {
    cursor: pointer;
    height: 7.5rem;
    width: 100%;
    padding: 1rem 1rem 0.5rem 1rem;
    text-align: center;
    transition: all 300ms 0s ease;
    background: var(--nav2-menu-bg);
}

.nav2-button-top-selected {
    cursor: pointer;
    height: 7.5rem;
    width: 100%;
    padding: 1rem 0.5rem 0.5rem 0.25rem;
    text-align: center;
    transition: all 300ms 0s ease;
    background: var(--ie-bg);
}

.nav2-button-panel-top:hover {
    background: var(--ie-bg);
}

.nav2-button {
    cursor: pointer;
    width: 100%;
    border-top: 1px solid #FFFFFF40;
    padding: 0.25rem 1rem 0.25rem 1rem;
    text-align: center;
    transition: all 300ms 0s ease;
    background: var(--nav2-menu-bg);
}

.nav2-button-selected {
    cursor: pointer;
    width: 100%;
    border-top: 1px solid #FFFFFF40;
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    text-align: center;
    transition: all 300ms 0s ease;
    background: var(--ie-bg-dark);
}

.nav2-button-panel:hover {
    border-top: #FFFFFF40;
    background: var(--ie-bg-dark);
}


.col-nav-title {
    font-size: 2rem;
}

.col-nav-title-english {
    font-size: 1rem;
}

.col-nav {
    width: var(--nav-bar-pc-width);
    padding: 0 0 0.5rem 0;
    color: white;
    background: var(--moz-bg);
    background: var(--webkit-bg);
    background: var(--ie-bg);
}

.dispose {
    display: none;
}

.nav-button-top {
    cursor: pointer;
    width: 100%;
    padding: 0.25rem 0;
    text-align: center;
    transition: all 300ms 0s ease;
    background: var(--ie-bg);
}

.nav-button-top:hover {
    background: var(--moz-bg-dark);
    background: var(--webkit-bg-dark);
    background: var(--ie-bg-dark);
}

.nav-button {
    color: white;
    cursor: pointer;
    border-top: 1px solid #FFFFFF40;
    padding: 0.25rem 0;
    text-align: center;
    transition: all 300ms 0s ease;
    background: var(--ie-bg);
}

.nav-button:hover {
    background: var(--moz-bg-dark);
    background: var(--webkit-bg-dark);
    background: var(--ie-bg-dark);
}

.nav-button-selected {
    background: var(--moz-bg-dark);
    background: var(--webkit-bg-dark);
    background: var(--ie-bg-dark);
}

.flex {
    display: flex;
}

.flex-item-article-side {
    width: 40mm;
}

.scroll-auto {
    overflow-x: hidden;
    overflow-y: auto;
}

@media(min-width: 150mm) {
    .flex-item-side-box {
        width: 60mm;
    }

    .flex-only-large {
        display: flex;
    }

    .content-scroll {
        top: 3rem;
        position: fixed;
        height: calc(100% - 3rem);
        width: calc(100% - var(--nav-bar-pc-width));
        overflow-y: auto;
        overflow-x: hidden;
    }

    .content-mustscroll {
        top: 3rem;
        position: fixed;
        height: calc(100% - 3rem);
        width: calc(100% - var(--nav-bar-pc-width));
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .content-scroll-v100 {
        position: fixed;
        height: 100%;
        width: calc(100% - var(--nav-bar-pc-width));
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .main-flex {
        display: flex;
    }

    .col-main {
        width: calc(100% - var(--nav-bar-pc-width));
    }
    
    .small-visible {
        display: none;
    }

    .large-content {
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    
    .large-col2 {
        width: calc((100% - var(--nav-bar-pc-width)) / 2);
    }

    .large-content-margin-half {
        margin-left: calc((100% - var(--nav-bar-pc-width)) / 2);
    }
}

@media(max-width: 150mm) {
    .large-visible {
        display: none;
    }

    .flex-item-side-box {
        width: 100%;
    }

    .flex-only-small {
        display: flex;
    }

    .flex-item-50percent-only-small {
        width: 50%;
    }

    .small-dispose {
        display: none;
    }

    .small-content {
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 0;
        height: 100%;
        margin-bottom: var(--small-menu-height);
    }

    .inline-flex {
        display: inline-flex;
    }

    .flex-activity-item-date {
        flex: 2 1 30%;
    }

    .flex-activity-item-week {
        flex: 2 2 10%;
    }

    .flex-activity-item-title {
        flex: 2 2 40%;
    }

    .flex-activity-item-category {
        flex: 2 2 30%;
    }

    .sns-activity-flex-item {
        flex: 2 2 50%;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
    }

    .small-sns-area {
        text-align: center;
        padding: 0.5mm;
        font-size: 0.8em;
        width: 100%;
        height: 6rem;
        border-left: 1px solid lightgray;
    }
    
    .content-scroll {
        top: 3rem;
        position: fixed;
        height: calc(100% - 3rem);
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .content-noscroll {
        top: 3rem;
        position: fixed;
        height: calc(100% - 3rem);
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .content-scroll-v100 {
        top: 0;
        position: fixed;
        height: calc(100% - 3rem);
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.inch {
    z-index: -1;
    position: fixed;
    height: 0;
    width: 1in;
}

.center {
    min-width: 50%;
    max-height: 30mm;
    margin: 0 auto;
}

.tag-normal {
    display: inline-block;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    padding: 0 0.25rem 0 0.25rem;
    background: var(--moz-category);
    background: var(--webkit-category);
    background: var(--ie-category);
    border: 1px solid #B8B8E0;
}

.tag-caution {
    display: inline-block;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    padding: 0 0.25rem 0 0.25rem;
    background: var(--moz-bg-tag-caution);
    background: var(--webkit-bg-tag-caution);
    background: var(--ie-bg-tag-caution);
    border: 1px solid #FFA0A0;
}

.sunday {
    background: var(--moz-sunday);
    background: var(--webkit-sunday);
    background: var(--ie-sunday);
}

.saturday {
    background: var(--moz-saturday);
    background: var(--webkit-saturday);
    background: var(--ie-saturday);
}

.activity-ribbon {
    padding: 0 0.25rem;
    text-align: center;
    background-color: #FFFFFF40;
    width: 100%;
}

.border-zero {
    border: 0px none #000000 !important;
}

.margin-bottom-1px {
    margin-bottom: 1px;
}

.activity-ribbon:hover {
    background: var(--moz-topic-dark);
    background: var(--webkit-topic-dark);
    background: var(--ie-topic-dark);
}

.border-left-2px {
    padding-left: 0.5mm;
    border-left: 2px solid lightgray;
}

.activity-slide {
    font-size: 1em;
}

.activity-slide-title {
    font-size: 1em;
    font-weight: bold;
}

.slide-image-box {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.slide-image-home {
    height: 350px;
    object-fit: cover;
}

.slide-image {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.menu-button-left {
    position: relative;
    cursor: pointer;
    padding: 0;
    background: var(--moz-bg);
    background: var(--webkit-bg);
    background: var(--ie-bg);
    color: white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    height: 3rem;
    text-align: center;
}


    .menu-button-left:hover {
        background: var(--moz-bg-dark);
        background: var(--webkit-bg-dark);
        background: var(--ie-bg-dark);
    }

    .menu-button-left:active {
        background: var(--moz-bg-dark);
        background: var(--webkit-bg-dark);
        background: var(--ie-bg-dark);
    }

.menu-button {
    position: relative;
    cursor: pointer;
    padding: 0;
    background-color: var(--main-bg-color);
    color: white;
    border-right: 1px solid white;
    height: 3rem;
    text-align: center;
}


.menu-button:hover {
    background: var(--moz-bg-dark);
    background: var(--webkit-bg-dark);
    background: var(--ie-bg-dark);
}

.menu-button:active {
    background: var(--moz-bg-dark);
    background: var(--webkit-bg-dark);
    background: var(--ie-bg-dark);
}

.fixed-body {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

.fixed-menu {
    width: 100%;
    z-index: 9999;
    position: fixed;
    height: var(--small-menu-height);
}

.article-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}


.article-item-box {
    cursor: pointer;
    width: 100%;
    height: 7.75rem;
    border-bottom: 1px solid lightgray;
    transition: all 200ms 0s ease;
    overflow-y: hidden;
}

.article-item-box:hover {
    border-left: 4px solid var(--main-bg-color-dark);
    background: var(--moz-topic-hover);
    background: var(--webkit-topic-hover);
    background: var(--ie-topic-hover);
}

.topic-ribbon {
    padding: 0.4rem;
    height: 2.5rem;
    background: var(--moz-topic-title);
    background: var(--webkit-toppic-title);
    background: var(--ie-toppic-title);
    color: var(--main-bg-color-dark);
    width: 100%;
}

.H1 {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    padding: 0.4rem 0.4rem 0.4rem 0.6rem;
    font-size: 1.5rem;
    background: var(--ie-bg-dark);
    color: white;
    height: 3rem;
    width: 100%;
    margin-bottom: 0.25rem;
}

.H1-annotation {
    display: block;
    text-align: left;
    padding: 0.4rem;
    font-size: 1.5rem;
    background: var(--ie-bg-annotation-dark);
    color: white;
    height: 3rem;
    width: 100%;
    margin-bottom: 0.25rem;
}

.H2 {
    display: block;
    text-align: left;
    font-weight: bold;
    font-size: 1em;
    padding: 0.25rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    background: var(--moz-bg-activity-title);
    background: var(--webkit-bg-activity-title);
    background: var(--ie-bg-activity-title);
    border-left: 4px solid var(--main-bg-color-dark);
    color: var(--main-bg-color-dark);
}

.H2-annotation {
    display: block;
    text-align: left;
    font-weight: bold;;
    font-size: 1em;
    padding: 0.25rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    background: var(--moz-bg-activity-title);
    background: var(--webkit-bg-activity-title);
    background: var(--ie-bg-activity-title);
    border-left: 4px solid var(--main-bg-annotation-dark);
    color: var(--main-bg-annotation-dark);
}

.H3 {
    display: block;
    font-size: 1em;
    font-weight: bold;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-left: 0.25rem;
    
    border-bottom: 1px solid var(--activity-title-border-color);
}

.p-msg {
    background-color: lightgray;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    
    background: var(--moz-bg-activity-title);
    background: var(--webkit-bg-activity-title);
    background: var(--ie-bg-activity-title);
}

.newsletter-other-button {
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 3rem;
    width: 3rem;
    background-color: #00000088;
    color: white;
}

.article-item-box:hover {
    background-color: #F0F8FF;
}

.article-item-image-area {
    width: 10rem;
}

.article-item-image {
    width: 40mm;
    height: 7.75rem;
    padding-bottom: 1px;
    object-fit: cover;
}

.article-item-title {
    color: var(--main-bg-color-dark);
    font-size: 1rem;
    font-weight: bold;
}

.article-item-content {
    text-align: justify;
    font-size: 0.9em;
    line-height: 130%;
}

.no-x-margin {
    margin-left: 0;
    margin-right: 0;
}

.font-gray {
    color: gray;
}

.min-width-7rem {
    min-width: 7rem;;
}

.width-100 {
    width: 100%;
}

.sns-icon {
    max-width: 90%;
    max-height: 2.8rem;
}

.sns-area-nav {
    height: 7rem;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0.25rem;
    text-align: center;
    border-top: 1px solid #FFFFFF40;
    color: white;
}

.sns-area {
    height: 6rem;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0 0.25rem 0.25rem 0.25rem;
    font-size: 0.8em;
    text-align: center;
    border-left: 1px solid lightgray;
}

.side-button {
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    height: 2.5rem;
    padding: 0.5rem;
    background: var(--moz-topic);
    background: var(--webkit-topic);
    background: var(--ie-topic);
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    width: 100%;
    margin-bottom: 0;
}

.side-button:hover {
    cursor: pointer;
    padding: 0.5rem;
    background: var(--moz-topic-dark);
    background: var(--webkit-topic-dark);
    background: var(--ie-topic-dark);
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    width: 100%;
}

.side-button:active {
    cursor: pointer;
    padding: 0.5rem;
    background-color: #D0D0D0;
    width: 100%;
}

.platoon-menu-button {
    cursor: pointer;
    height: 3rem;
    padding: 0.75rem 0.5rem 0 0.5rem;
    background: var(--moz-topic);
    background: var(--webkit-topic);
    background: var(--ie-topic);
    border-right: 1px solid lightgray;
    flex: 2 2 33%
}

.platoon-menu-button-selected {
    color: white !important;
    font-weight: bold;
    cursor: pointer;
    padding: 0.75rem 0.5rem 0 0.5rem;
    background: var(--moz-topic-dark2);
    background: var(--webkit-topic-dark2);
    background: var(--ie-topic-dark2);
    width: 100%;
}

.vertical-middle {
    margin-top: 0.8rem;
}

.theme-selected {
    background: var(--moz-bg-dark);
    background: var(--webkit-bg-dark);
    background: var(--ie-bg-dark);
}

.theme-color {
    background: var(--moz-bg);
    background: var(--webkit-bg);
    background: var(--ie-bg);
}

.pointer {
    cursor: pointer;
}

.menu-ribbon {
    width: 100%;
    margin: 0;
    background: var(--moz-bg);
    background: var(--webkit-bg);
    background: var(--ie-bg);
    height: 3.1rem;
}

.max-100percent {
    max-width: 100%;
    max-height: calc(100% - 3rem);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.left-window-border {
    border-left: 8px solid var(--main-bg-color);
}

.gothic {
    font-family: "Noto Sans JP";
}

.date-strong {
    color: black;
    font-weight: bold;
}

.date-thin {
    color: gray;
    font-weight: lighter;
}

.grid-col1 {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr;
    list-style: none;
    padding-left: 0;
}

.grid-col7 {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    list-style: none;
    padding-left: 0;
}

.percent14 {
    width: 14%;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.tile-li {
    font-family: "Noto Sans JP";
    cursor: pointer;
    width: 100%;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    text-align: left;
    padding: 0;
    min-height: 6rem;
}

.tile-li-today {
    font-family: "Noto Sans JP";
    cursor: pointer;
    width: 100%;
    animation-name: tile-today-anime;
    animation-duration:  0.5s;
    animation-iteration-count: infinite;
    text-align: left;
    padding: 0;
    min-height: 6rem;
}

@keyframes tile-today-anime {
    0%{
        border: 3px solid #000000A0
    }
    50%{
        border: 3px solid #00000040
    }
    100%{
        border: 3px solid #000000A0
    }
}

.tile {
    cursor: pointer;
    width: 25%;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    text-align: center;
    padding: 1rem 0 0 0;
}

.nothing {
    color: white;
    background: var(--moz-caution);
    background: var(--webkit-caution);
    background: var(--ie-caution);
}

.selected {
    color: white;
    background: var(--moz-bg-dark);
    background: var(--webkit-bg-dark);
    background: var(--ie-bg-dark);
}

.partial-selected {
    color: white;
    background: var(--moz-bg);
    background: var(--webkit-bg);
    background: var(--ie-bg);
}

.expander {
    color: inhert;
}

.expander:hover {
    background-color: #00000040;
}

.horizon-scroll {
    overflow: hidden;
}

.horizon-scroll span{
    display: inline-block;
    padding-left: 100%;
    white-space : nowrap;
    line-height : 1em;
    animation : fes-scrollAnime 60s linear infinite;
  }

  @keyframes fes-scrollAnime{
      0% { transform: translateX(0)}
    100% { transform: translateX(-100%)}
  }

  .activity {
    cursor: pointer;
    background-color: #0000D010;
    color: var(--main-bg-color-dark);
    border-bottom: 1px solid var(--main-bg-color-dark);
}