:root {
	--close-button-color1: #A0A0A0;
    --close-button-color2: #B0B0B0;

    --moz-bg-close-button:  -moz-linear-gradient(top, var(--close-button-color1), var(--close-button-color2));
    --webkit-bg-close-button: -webkit-linear-gradient(top, var(--close-button-color1), var(--close-button-color2));
	--ie-bg-close-button: linear-gradient(to bottom, var(--close-button-color1), var(--close-button-color2));
	
	--close-button-hover-color1: #808080;
    --close-button-hover-color2: #909090;

    --moz-bg-close-button-hover:  -moz-linear-gradient(top, var(--close-button-hover-color1), var(--close-button-hover-color2));
    --webkit-bg-close-button-hover: -webkit-linear-gradient(top, var(--close-button-hover-color1), var(--close-button-hover-color2));
    --ie-bg-close-button-hover: linear-gradient(to bottom, var(--close-button-hover-color1), var(--close-button-hover-color2));
}

#dialogBackground {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.7;
}

@media(min-width: 150mm) {
	.dialogContent {
		z-index: 999;
		position: fixed;
		right: 0;
		top: calc(100% + 15mm);
		width: calc(50% - 30mm);
		height: 100%;
		background-color: #FFFFFF;
		transition: all 300ms 0s ease;
		box-shadow: 0 0 2mm 2mm rgba(0, 0, 0, .5)
	}

	.dialog-active-activity {
		top: 0%;
	}

	.dialog-scrollbar-activity {
		height: calc(100% - 2rem);
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.dialog-active-annotation {
		top: 9rem;
	}

	.dialog-scrollbar-annotation {
		height: calc(100% - 8rem);
		overflow-y: scroll;
		overflow-x: hidden;
	}
}

@media(max-width: 150mm) {
	.dialogContent {
		z-index: 999;
		position: fixed;
		right: 0;
		top: calc(100% + 15mm);
		width: 100%;
		height: 100%;
		background-color: #FFFFFF;
		transition: all 300ms 0s ease;
		box-shadow: 0 0 2mm 2mm rgba(0, 0, 0, .5)
	}

	.dialog-active-activity {
		top: 5rem;
	}

	.dialog-scrollbar-activity {
		height: calc(100% - 8rem);
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.dialog-active-annotation {
		top: 10rem;
	}

	.dialog-scrollbar-annotation {
		height: calc(100% - 13rem);
		overflow-y: scroll;
		overflow-x: hidden;
	}
}

.button-close {
	cursor: pointer;
	background: var(--moz-bg-close-button);
	background: var(--webkit-bg-close-button);
	background: var(--ie-bg-close-button);
	color: white;
	height: 3rem;
	padding: 0.5rem 0;
}

.button-close:hover {
	background: var(--moz-bg-close-button-hover);
	background: var(--webkit-bg-close-button-hover);
	background: var(--ie-bg-close-button-hover);
}

.center {
	margin-left: auto;
	margin-right: auto;
}

.flex-wrap {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 0 auto;
}

.thum-box {
	background-color: lightgray;
	border: 1px solid lightgray;
	text-align: center;
	max-width: 65mm;
	margin: 0.5rem 2mm;
	padding-bottom: 0.25rem;
}

.thum-selected {
	background-color: lightgray;
	border: 8px solid gray;
}