.min-width-7rem {
    min-width: 7rem;
}

.platoon-flex {
    display: flex;
}

.platoon-col33percent {
    flex: 2 2 33%;
}

.platoon-col16percent {
    flex: 2 2 30mm;
}

.platoon-col83percent {
    flex: 2 2 83%;
}

.image-max {
    max-width: 90%;
}