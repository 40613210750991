:root {
    --activity-title-color1: #E8E8E8;
    --activity-title-color2: #F8F8F8;

    --moz-bg-activity-title:  -moz-linear-gradient(top, var(--activity-title-color1), var(--activity-title-color2));
    --webkit-bg-activity-title: -webkit-linear-gradient(top, var(--activity-title-color1), var(--activity-title-color2));
    --ie-bg-activity-title: linear-gradient(to bottom, var(--activity-title-color1), var(--activity-title-color2));
    
    --activity-title-border-color: #C0C0C0;

    --activity-subtitle-border-color: #88A0FF;
}

.activity-title {
    text-align: center;
    font-size: 1.5rem;
    padding-left: 0.25rem;
}

.newsletter-border {
    max-width: 100%;
    max-height: 100%;
}

.background-lightgray {
    position: fixed;
    background-color: lightgray;
    width: 100%;
    height: 100%;
}

.newsletter-body {
    z-index: 10;
    position: fixed;
    top: 3rem;
    height: calc(100% - 3rem);
    width: auto;
}

.font-lightfray {
    color: gray;
}

.max-100percent {
    max-width: 100%;
    max-height: 100%;
}

.activity-info {

    text-align: left;
    padding: 0.25rem;
    margin-bottom: 0.25rem;
    background: var(--moz-bg-activity-title);
    background: var(--webkit-bg-activity-title);
    background: var(--ie-bg-activity-title);
    border-left: 4px solid var(--activity-title-border-color);
}

.activity-subtitle {
    text-align: left;
    padding-left: 0.25rem;
    background: var(--moz-bg-activity-title);
    background: var(--webkit-bg-activity-title);
    background: var(--ie-bg-activity-title);
    border-left: 4px solid var(--activity-title-border-color);
}

.strong-item {
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    margin-top: 0.25rem;
    padding-bottom: 0.5rem;
    text-align: center;
}

.normal-item {
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    padding-left: 0.25rem;
    margin-top: 0.25rem;
}