.annotation {
    background-color: #00D00010;
    color: #008000;
    border-bottom: 1px solid #008000;
}


.annotation-church {
    background-color: #00000010;
    color: black;
    border-bottom: 1px solid black;
}