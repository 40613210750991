:root {
    --mincho-font-family: "Sawarabi Mincho";
    --gothic-font-family: "Noto Sans JP";
    --white-object-bg: #FFFFFF40;
    --white-object-bg2: #FFFFFF80;
    --black-object-bg: #00000060;
    --black-object-bg2: #000000A0;
}

.question-box2 {
    border: 1px solid black;
    margin: 0.5em;
    padding: 0.5em;
}

.occation {
    color: white;
    font-family: var(--mincho-font-family);
}

.border-left-2mm {
    border-left: 2mm solid gray;
    background-color: white;
}

@media(min-width: 150mm) {
    .editor-main-frame {
        position: absolute;
        top: 3rem;
        left: 60mm;
        right: 0;
        bottom: 0;
    }

    .grid-seperator {
        background-color: gray;
        width: 100%;
        height: 100%;
    }
}

@media(max-width: 150mm) {
    .editor-main-frame {
        position: absolute;
        top: 3rem;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.button-viewer {
    background-color: var(--black-object-bg);
    cursor: pointer;
    color: white;
    font-family: var(--mincho-font-family);
    border: none;
    padding: 0.5em 1em;
}

.button-action {
    cursor: pointer;
    color: white;
    font-family: var(--mincho-font-family);
    border: none;
    padding: 0.5em 1em;
}

.button-candidation-anime {
    animation: fadeInFromBottomAnime 0.7s 1, builkingAnime 0.7s infinite;
}

.button-next-anime {
    animation: fadeInFromRightAnime 0.7s 1, builkingAnime 0.7s infinite;
}

@keyframes builkingAnime {
    0% {
        background-color: var(--black-object-bg);
        border-bottom: 2px solid var(--white-object-bg);
    }
    30% {
        background-color: var(--black-object-bg2);
        border-bottom: 2px solid var(--white-object-bg2);
    }
    100% {
        background-color: var(--black-object-bg);
        border-bottom: 2px solid var(--white-object-bg);
    }
}

@keyframes fadeInFromBottomAnime {
    0% {
        margin-top: 20mm;
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes fadeInFromRightAnime {
    0% {
        margin-right: -20mm;
        opacity: 0;
    }
    100% {
        margin-right: 0;
        opacity: 1;
    }
}

.img-history {
    position: absolute;
    z-index: -10;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-history-editor {
    position: absolute;
    z-index: -10;
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.history-main {
    z-index: 100;
}

.color-white {
    color: white;
}

hr {
    width: 100%;
    border-top: 1px solid #FFFFFFA0;
    border-bottom: none;
    border-left: none;
    border-right: none;
}

.font-black {
    color: black;
}

.history-viewer-main {
    height: 100%;
    width: 100%;
    background-color: darkgray;
}

.history-title {
    text-align: center;
}

.h2 .history-title {
    font-size: 1.6rem;
}

.history-title-column2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-family: var(--mincho-font-family);
}

.button-subHistory {
    display: block;
    border-left: 3px solid #FFFFFFA0;
    cursor: pointer;
    background-color: #FFFFFF01;
    padding: 1mm;
    margin-bottom: 1mm;
}

.button-subHistory-selected {
    background: linear-gradient(var(--white-object-bg) 0%, #FFFFFF15 30%, #FFFFFF15 100%);
}

.subHistory-title {
    font-size: 1.2rem;
    font-family: var(--mincho-font-family);
}

.subHistory-generation {
    font-size: 0.8rem;
    font-family: var(--mincho-font-family);
}

.font-mincho {
    font-family: var(--mincho-font-family);
    font-weight: lighter;
}

.question-box {
    width: 5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    font-family: var(--gothic-font-family);
    color: white;
    background-color: var(--white-object-bg);
    border: 2px solid white;
    display: inline-block;
    text-align: center;
}

.question-box-open {
    margin-left: 0.5em;
    margin-right: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-family: var(--gothic-font-family);
    color: white;
    background-color: var(--white-object-bg);
    border: 2px solid white;
    display: inline;
    text-align: center;
}

.question-box-editor {
    width: 5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    font-family: var(--gothic-font-family);
    color: black;
    background-color: var(--black-object-bg);
    border: 2px solid black;
    display: inline-block;
    text-align: center;
}

.next-button {
    text-align: left;
    width: 10em;
}

.grid-col2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.content-edit-button {
    margin-left: 1px;
    margin-right: 1px;
    width: 100%;
}

.scroll-y {
    overflow-y: auto;
    overflow-x: hidden;
}

.text-area {
    height: 5rem;
}

.candidation-button {
    text-align: left;
    width: 100%;
    margin-bottom: 1mm;
}

.outline {
    border: gray;
    margin: 0.5rem 0.5rem 0.5rem 0;
}

.hr-bold {
    border-top: 5px solid black;
    margin: 1em;
}

.hr-thin {
    border-top: 1px solid darkgray;
    margin: 1em;
}

.white-text {
    font-family: var(--mincho-font-family);
    color: white;
}

.w-70percent {
    margin: 0 auto 0 auto;
    width: 70%;
}

table.result {
    margin: 0 1em;
    width: 100%;
    border-collapse: collapse;
    border: 0 solid white; /* 外側の枠線 */
 }

 table.result td {
    border-width: 1px 0px; /* 上下だけ引く */
    border-color: white;   /* 線色：緑 */
    border-style: solid;   /* 線種：実線 */
    padding: 0.3em;        /* セル内側の余白 */
 }